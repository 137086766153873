<template>
    <v-container
        fluid
    >
        <v-row>
            <v-col
                cols="12"
                md="8"
            >
                <h2>OPTIDATA A/S</h2>
                Rugaardsvej 321
                <br>
                5210 Odense x
                <br>
                CVR: 1233456
                <br>
                Tlf: 12345678
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    data() {
        return {
        }
    },
    computed: {
    },
    created() {        
    },    
    methods: {
    }
}
</script>
